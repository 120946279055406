import { useState } from "react";

import { MEDIA_URL } from "../../utils/constants";

import Modal from "../UI/Modal";
import Button from "../UI/Button";
import Dropzone from "../Dropzone";

import EditWrapper from "../EditWrapper";

type Props = {
    icon: string;
    text: string;
    component_id: string | number;
    sectionKey: string;
    onSubmit: (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => void;
};

const IconWithText = ({
    component_id,
    icon,
    text,
    onSubmit,
    sectionKey,
}: Props) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [uploadedIcon, setUploadedIcon] = useState<string>(icon);

    const toggleModal = () => {
        setModalOpen((p) => !p);
    };

    const handleIconUpload = (src: string): void => {
        setUploadedIcon(src);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        onSubmit(
            {
                component_title: data.text,
                component_description: uploadedIcon,
            },
            sectionKey,
            component_id
        );
        toggleModal();
    };

    return (
        <>
            <Modal isOpen={modalOpen} toggleModal={toggleModal}>
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={icon}
                            handleIconUpload={handleIconUpload}
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="text"
                            type="text"
                            placeholder="Text"
                            required
                            defaultValue={text}
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
            {/* icon and text */}
            <EditWrapper ref={undefined} toggleModal={toggleModal}>
                <div className="flex items-center space-x-2">
                    <img src={MEDIA_URL + icon} height={80} width={80} alt="" />
                    <div>{text}</div>
                </div>
            </EditWrapper>
        </>
    );
};

export default IconWithText;
