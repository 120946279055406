import React, { useState } from "react";
import { toast } from "react-toastify";

import { useUser } from "../../context/UserContext";
import { Axios } from "../../utils/axios";
import { toast_error_settings } from "../../utils/constants";

import EditWrapper from "../EditWrapper";
import Button from "../UI/Button";
import Modal from "../UI/Modal";

type Props = {
    title?: string;
    text?: string;
    room_id?: string | number;
    fetchData: () => void;
    setLoading?: () => void;
    add?: boolean;
};

const ZoneCaracteristic = ({
    text,
    room_id,
    title,
    fetchData,
    setLoading,
    add = false,
}: Props) => {
    const { userState, setUser } = useUser();

    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const toggleEditModal = () => {
        setEditModalOpen((p) => !p);
    };

    const toggleDeleteModal = () => {
        setDeleteModalOpen((p) => !p);
    };

    const handleEditSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading && setLoading();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            let caracteristic_id = "";

            if (!add) {
                const caract = await Axios.post(
                    "get_caracteristics_by_room_id",
                    {
                        adminid: userState?.admin_id,
                        token: userState?.admin_login_token,
                        room_id: room_id,
                    }
                );
                caracteristic_id = caract.data.find(
                    (item: any) => item.caracteristic === title
                )?.id;
            }

            const res = await Axios.post(
                add ? "add_room_caracteristics" : "update_room_caracteristics",
                {
                    adminid: userState?.admin_id,
                    token: userState?.admin_login_token,
                    room_id: room_id,
                    caracteristic: data.caracteristic,
                    description: data.description,
                    id: caracteristic_id,
                }
            );
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        toggleEditModal();
    };

    const handleDeleteSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading && setLoading();
        try {
            const caract = await Axios.post("get_caracteristics_by_room_id", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                room_id: room_id,
            });
            const caracteristic_id = caract.data.find(
                (item: any) => item.caracteristic === title
            )?.id;

            const res = await Axios.post("delete_room_caracteristics", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                id: caracteristic_id,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        toggleDeleteModal();
    };

    return (
        <>
            <Modal
                size="2xl"
                isOpen={editModalOpen}
                toggleModal={toggleEditModal}
            >
                <form className="mt-6" onSubmit={handleEditSubmit}>
                    <div className="input-row">
                        <input
                            name="caracteristic"
                            type="text"
                            placeholder="Title *"
                            defaultValue={title || ""}
                        />
                    </div>

                    <div className="input-row">
                        <textarea
                            name="description"
                            placeholder="Description *"
                            defaultValue={text || ""}
                            rows={4}
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleEditModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal isOpen={deleteModalOpen} toggleModal={toggleDeleteModal}>
                <form className="mt-6" onSubmit={handleDeleteSubmit}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this item?
                    </h4>
                    <div className="flex space-x-4">
                        <Button
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-auto mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>

            {add ? (
                <button
                    onClick={() => toggleEditModal()}
                    className="min-h-[50px] w-full flex items-center justify-center border"
                >
                    + Add Caracteristic
                </button>
            ) : (
                <EditWrapper
                    ref={undefined}
                    onDelete={toggleDeleteModal}
                    toggleModal={toggleEditModal}
                >
                    <h2
                        className="font-bold text-xl mb-2"
                        dangerouslySetInnerHTML={{
                            __html: title || "",
                        }}
                    />
                    <p
                        className="whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{ __html: text || "" }}
                    />
                </EditWrapper>
            )}
        </>
    );
};

export default ZoneCaracteristic;
