import React, { useEffect, useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import { MEDIA_URL } from "../utils/constants.ts";

import { Axios } from "../utils/axios";
import { toast } from "react-toastify";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
};

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    minHeight: 75,
    minWidth: 75,
    padding: 4,
    boxSizing: "border-box",
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
};

const img = {
    display: "block",
    width: "auto",
    height: "100%",
};

const Dropzone = (props) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        setFiles([{ preview: MEDIA_URL + props.defaultValue }]);
    }, [props.defaultValue]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": [],
        },
        onDrop: async (acceptedFiles) => {
            acceptedFiles.forEach(async (file) => {
                let fd = new FormData();
                fd.append("file", file);

                await Axios({
                    method: "post",
                    url: `upload_file`,
                    data: fd,
                })
                    .then((res) => {
                        if (res.data.toString() === "0") {
                            throw new Error(
                                "Something went wrong please check your image size and type and try again!"
                            );
                        }
                        if (res.data !== "error") {
                            props.handleIconUpload(res.data);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        toast.error(err.message);
                        throw new Error(err);
                    });

                // setFiles(
                //     acceptedFiles.map((file) =>
                //         Object.assign(file, {
                //             preview: URL.createObjectURL(file),
                //         })
                //     )
                // );
            });
        },
    });

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                    }}
                    alt=""
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const style = useMemo(
        () => ({
            ...baseStyle,
        }),
        []
    );

    return (
        <section>
            <div {...getRootProps({ style: style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside style={thumbsContainer} className={props.previewClass}>
                {thumbs}
            </aside>
        </section>
    );
};

export default Dropzone;
